import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_nl_mobilemdx = graphql`
        query PageQuery_service_nl_mobilemdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/mobile/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Mobiel & Diversen",
  "path": "/service/mobile",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "nl",
  "icon": "bx-mobile-alt",
  "tags": ["ontwikkelaar", "android", "invoer"],
  "experience": [{
    "title": "Streaming: Live 3D - Mobiel",
    "task": "Lead Integratie / Netwerken",
    "tags": ["lead", "network", "ontwikkelaar"],
    "outcome": ["Implementatie scrubbing + tijdlijnbuffers", "Integratie van bedrijfsframework", "Generieke toegang tot metadata", "Soepele 3D streams"],
    "link": ["https://www.gamefacebyeredivisie.nl"],
    "copyright": "Beyond Sports B.V.",
    "image": ["./images/mobile/bs-gf-app-01.jpg"]
  }, {
    "title": "Revalidatie In 3D",
    "task": "Hoofdontwikkelaar Front-end / Pijplijn",
    "tags": ["lead", "ontwikkelaar", "unity3d"],
    "outcome": ["Kleureffecten dmv LUT", "Interactieve omgevingskenmerken", "Realistische belichting (pre-HDRP)", "Bidirectionele communicatie back-end"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Veerboot Amsterdam",
    "task": "Unity / Belichting / Visuele Nabewerking",
    "tags": ["ontwikkelaar", "belichting", "nabewerking"],
    "outcome": ["Shaders voor water & cartoonranden", "Opgeleverd binnen strakke deadline", "Prefabs met animaties & blendshapes", "Aanmaak van video's met v4 van Unity"],
    "link": ["http://depont.submarinechannel.com"],
    "image": ["./images/graphics/nmt-sub-de-pont-01.jpg"],
    "copyright": "Submarine B.V."
  }]
};
const layoutProps = {
  PageQuery_service_nl_mobilemdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "klein-apparaat-grote-app",
      "style": {
        "position": "relative"
      }
    }}>{`Klein apparaat, grote app`}<a parentName="h1" {...{
        "href": "#klein-apparaat-grote-app",
        "aria-label": "klein apparaat grote app permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Door ervaring met de belangrijkste platformen sinds het ontstaan van moderne smartphones kan ik u helpen bij het bouwen van software voor deze toestellen.`}</p>
    <h2 {...{
      "id": "vaardigheden",
      "style": {
        "position": "relative"
      }
    }}>{`Vaardigheden`}<a parentName="h2" {...{
        "href": "#vaardigheden",
        "aria-label": "vaardigheden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`5+ jaar ervaring met Android`}</li>
      <li parentName="ul">{`2+ jaar ervaring met iOS (vooral high-level)`}</li>
      <li parentName="ul">{`Ervaren met een breed scala aan soorten apps`}</li>
      <li parentName="ul">{`Kennis van bijzondere apparaten, zoals de `}<a parentName="li" {...{
          "href": "https://time.com/4173507/myo-armband-review/"
        }}>{`Myo armband`}</a>{` of `}<a parentName="li" {...{
          "href": "https://www.ultraleap.com/product/leap-motion-controller/"
        }}>{`Leap Motion controller`}</a></li>
    </ul>
    <h2 {...{
      "id": "technisch",
      "style": {
        "position": "relative"
      }
    }}>{`Technisch`}<a parentName="h2" {...{
        "href": "#technisch",
        "aria-label": "technisch permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Unity 3D: sinds v3`}</li>
      <li parentName="ul">{`Android: SDK, NDK & JNI`}</li>
      <li parentName="ul">{`Tecnnieken zoals AOT zijn geen probleem`}</li>
      <li parentName="ul">{`Interne systemen zoals `}<a parentName="li" {...{
          "href": "https://github.com/strangeioc/strangeioc/issues/136#issuecomment-85812549"
        }}>{`IL2CPP in Unity`}</a></li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      